<section class="py-6 px-4 sm:py-12 mx-auto max-w-screen-xl">
    <div class="flex flex-col gap-5 mx-auto w-full sm:max-w-screen-sm mb-5">
        <div class="font-bold text-2xl text-slate-700 text-center">Event <span class="text-teal-500">Calendar</span>
        </div>
        <span class="font-medium text-sm text-slate-600 text-center">
            Discover our upcoming events on the Avini Health Event Calendar. Join us for webinars, workshops, and
            community gatherings where we share insights on natural health, pain management, and immune support. Stay
            connected with our latest events to learn more about our scientifically-backed products and holistic
            wellness approach.</span>
    </div>

    <!-- Calender -->

    <ng-template>
      <div [ngSwitch]="view" class="mt-12">
          <!-- We are not using this one -->
          <!-- <ng-template #customMonthHeader>
              <div class="custom-header-container border-t-[1px] border-x-[1px]">
                <p class="custom-header" *ngFor="let day of days" [ngClass]="{ 'highlight-today': getTodayDayName() === day }">{{day}}</p>
            </div>
          </ng-template> -->
          <ng-template #customCellTemplate let-day="day" let-locale="locale">
              <div class="cal-cell-top">
                <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
                  >{{ day.badgeTotal }}</span
                >
                <span class="cal-day-number"
                  >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
                >
              </div>
              <!-- <small style="margin: 5px"
                >There are {{ day.events.length }} events on this day</small
              > -->
              <div *ngIf="getSpecificDateContent(day.date) as specificDate">
                  <div [ngClass]="specificDate.backgroundColor" class="rounded-lg sm:m-2 sm:p-3 sm:text-left m-[2px] break-all" >
                      <p class="font-semibold text-[12px]">{{specificDate.content}}</p>
                      <p class="text-[12px]">{{specificDate.time}}</p>
                  </div>                       
              </div>
          </ng-template>
          <!-- <mwl-calendar-month-view
            [viewDate]="viewDate"
            [headerTemplate]="customMonthHeader"
            >
          </mwl-calendar-month-view> -->
          <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [cellTemplate]="customCellTemplate"
          [weekStartsOn] = "1"
        >
        </mwl-calendar-month-view>
      </div>
    </ng-template>


    <!-- Products -->
    <div class="w-full mt-12">
        <builder-component model="section" [options]="builder.options"
            *ngIf="!builder.noBuilderPageForUrl" (load)="builder.noBuilderPageForUrl = $event ? false : true"
            (error)="builder.noBuilderPageForUrl = true" [data]="builder.data"
            [context]="builder.context">
            <div class="spinner"></div>
        </builder-component>
    </div>
</section>