import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerComponent } from '../../shared/model/video-player/video-player.component';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.sass']
})
export class TestimonialsComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };
  
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openVideoPlayer(title:string, videoUrl: string) {
    this.dialog.open(VideoPlayerComponent, {
      data: {
        title: title,
        videoUrl: videoUrl,
      },
      panelClass: ["video-player-popup", "w-full", "mx-auto", "max-width-900"]
    });
  }

}
