import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-and-returns-policy',
  templateUrl: './refund-and-returns-policy.component.html',
  styleUrls: ['./refund-and-returns-policy.component.scss']
})
export class RefundAndReturnsPolicyComponent implements OnInit {
  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };
  constructor() { }

  ngOnInit(): void {
  }

}
