import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scientific-research',
  templateUrl: './scientific-research.component.html',
  styleUrls: ['./scientific-research.component.sass']
})
export class ScientificResearchComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
