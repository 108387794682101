<div id="signup">
  <div class="container mx-auto text-center m-[4rem]" *ngIf="!isShowInfo">
    <h2 class="text-2xl font-semibold mb-4 welcome-text">Enter your email address to sign in or to create an account
    </h2>
    <div class="items-center flex-col justify-center mb-4 mt-3 flex">
      <div class="w-full max-w-xs">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unifiedapplication_email_Add"></mat-label>
          <input name="email" matInput [(ngModel)]="userInfo.email" email="true" noempty="true" validate nospaceallow="true"
            checkOutSection="true" />
        </mat-form-field>
      </div>
      <p class="text-sm mt-1">You are signing up as a Retail Customer.</p>
    </div>

    <div class="flex justify-center">
      <button class="btn text-white w-full max-w-xs get-started-button px-6 py-2 rounded" validate trigger-check="true"
        (success)="validEmailData(userInfo.email)">Continue →</button>
    </div>
  </div>

  <div class="container mx-auto text-center m-[4rem]" *ngIf="isShowInfo">
    <h2 class="text-2xl font-semibold mb-[2rem] Signup-Information-text">Enter Information Below</h2>
    <p class="text-lg mb-4 signup-text">
      {{userInfo.email}}
    </p>
    <div class="different-email mb-4">
      <a class="change-email" (click)="isShowInfo = false;">Use a Different Email</a>
    </div>
    <p class="text-base mb-6 address-text">
      You are shopping with {{ userService.enrollerInfo.FirstName +' ' +userService.enrollerInfo.LastName}} ({{userService.enrollerInfo.WebAlias}}) -
      <a (click)="showEnroller = !showEnroller" class="change-address">Change</a>
    </p>
    <form>
      <div class="w-1/2 mx-auto" *ngIf="showEnroller">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="Enroller’s Referral Code"></mat-label>
          <input name="enroller" matInput [(ngModel)]="userInfo.webAlias" inputmobilespacendash="true"
            noempty="true" validname="true" checkOutSection="true" validate (focusout)="verifyWebAlias()"/>
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_first_name"></mat-label>
          <input name="firstName" matInput [(ngModel)]="userInfo.firstName" inputmobilespacendash="true"
            noempty="true" validname="true" checkOutSection="true" validate />
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_last_name"></mat-label>
          <input name="lastName" matInput [(ngModel)]="userInfo.lastName" inputmobilespacendash="true"
            noempty="true" validname="true" checkOutSection="true" validate />
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unifiedapplication_choose_password"></mat-label>
          <input name="password" type="password" matInput [(ngModel)]="userInfo.password"
            checkOutSection="true" id="txtPassword" nospaces="true" zxcvbn="" noempty="true" nospaceallow="true"
            regxvalid="true" regxvalidValue="((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})" validate />
        </mat-form-field>
          <!-- <p class="text-sm text-gray-600 mt-1 text-start">Password must be 8 characters 1 uppercase 1 number</p> -->
      </div>
      <div class="w-1/2 mx-auto">
        <div class="relative flex items-center mb-4">
          <h3 class="text-lg font-semibold flex-1">Shipping Address</h3>
          <hr class="absolute inset-0 border-t border-gray-300"
            style="top: 50%; left: 25%; width: 75%; transform: translateY(-50%);">
        </div>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_country"></mat-label>
          <mat-select [(value)]="userInfo.applicantAddress.CountryCode" validate validate-select checkOutSection="true" (selectionChange)="getStates(userInfo.applicantAddress.CountryCode)">
            <mat-option *ngFor="let country of allowedCountries" [value]="country.CountryCode">
              {{ country.CountryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_address"></mat-label>
          <input name="streetAddress" matInput [(ngModel)]="userInfo.applicantAddress.Street1"
            checkOutSection="true" validate noempty="true" validate-maxlength="25" maxlength="25" />
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_address2"></mat-label>
          <input name="apartment" matInput [(ngModel)]="userInfo.applicantAddress.Street2" />
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_city"></mat-label>
          <input name="city" matInput [(ngModel)]="userInfo.applicantAddress.City"
            inputmobilespacendash="true" noempty="true" validname="true" checkOutSection="true" validate />
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_state"></mat-label>
          <mat-select [(ngModel)]="userInfo.applicantAddress.Region" validate validate-select checkOutSection="true">
            <mat-option *ngFor="let state of states" [value]="state.StateCode">
              {{ state.StateName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unified_shipto_zipcode"></mat-label>
          <input name="zip" matInput [(ngModel)]="userInfo.applicantAddress.PostalCode"
            [attr.regexcountry]="selectedCountry.toUpperCase()" regxvalidzip="true" noempty="true" validate
            checkOutSection="true"/>
        </mat-form-field>
      </div>
      <div class="w-1/2 mx-auto">
        <div class="relative flex items-center mb-4">
          <h3 class="text-lg font-semibold flex-1">Billing Address</h3>
          <hr class="absolute inset-0 border-t border-gray-300"
            style="top: 50%; left: 25%; width: 75%; transform: translateY(-50%);">
        </div>
      </div>
      <div class="w-1/2 mx-auto flex flex-col">
        <mat-form-field class="ds-validate" fxFlex appearance="outline">
          <mat-label translate="unifiedapplication_mobile_phone"></mat-label>
          <input name="phoneNumber" matInput [(ngModel)]="userInfo.primaryPhone" phoneno="true"
            inputspace="true" checkOutSection="true" noempty="true" validate checkOutSection="true"
            minlength="10" inputonlynumber="true" />
        </mat-form-field>
        <p class="text-sm text-gray-600 mt-1 text-start">Please include your country code (+XXX) with your phone
          number</p>
      </div>

      <div class="text-center mt-4">
        <p class="signup-form-text">You are shopping with {{ userService.enrollerInfo.FirstName +' ' +userService.enrollerInfo.LastName}} ({{userService.enrollerInfo.WebAlias}}) - <a (click)="showEnroller = !showEnroller"
            class="change-address">Change</a></p>
        <div class="text-center mx-auto mt-6">
          <button class="btn text-white w-1/2 max-w-xs-30 get-started-button px-6 py-2 rounded" validate trigger-check="true"
          (success)="createAccount()">
            Continue →
          </button>
        </div>
        <p class="mt-2 signup-form-text">Have an account? <a routerLink="/login" class="change-address">Sign In</a></p>
      </div>
    </form>
  </div>
</div>