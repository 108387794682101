import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CheckoutComponent } from "./checkout/checkout.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "src/app/components/pages/signup/signup.component";
// import { ApplicationComponent } from "./application/application.component";
import { AuthGuard } from "../shared/services/auth.guard.service";
import { HomeComponent } from "./home/home.component";
import { CompleteComponent } from "./complete/complete.component";
import { LoggedInAuthGuard } from "../shared/services/loggedin.guard.service";
import { ContactComponent } from "./contacts/contacts.component";
import { BecomeADistributorComponent } from "./become-a-distributor/become-a-distributor.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { ScientificResearchComponent } from "./scientific-research/scientific-research.component";
import { AppComponent } from "./app/app.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { ReferralCodeEntryComponent } from "./referral-code-entry/referral-code-entry.component";
import { EmailAddressComponent } from "./email-address/email-address.component";
import { LogindetailsComponent } from "./logindetails/logindetails.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SignupWithEmailComponent } from "./signup-with-email/signup-with-email.component";
import { SignUpInformationComponent } from "./sign-up-information/sign-up-information.component";
import { TermsComponent } from "./terms/terms.component";
import { EventCalendarComponent } from "./event-calendar/event-calendar.component";
import { ShippingAddressComponent } from "./shippingaddress/shippingaddress.component";
import { ApplicationDeprecatedComponent } from "./application-deprecated-(not-in-use)/application-deprecated.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ShippingPolicyComponent } from "./shipping-policy/shipping-policy.component";
import { RefundAndReturnsPolicyComponent } from "./refund-and-returns-policy/refund-and-returns-policy.component";
import { BioComponent } from "./bio/bio.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "checkout", component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: "contact", component: ContactComponent },
  { path: "terms-of-service", component: TermsOfServiceComponent },
  { path: "referral-code-entry", component: ReferralCodeEntryComponent },
  { path: "email-address", component: EmailAddressComponent },
  // { path: "logindetails", component: LogindetailsComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "signup-with-email", component: SignupWithEmailComponent },
  { path: "sign-up-information", component: SignUpInformationComponent },
  { path: "terms-condition", component: TermsComponent },
  { path: "event-calendar", component: EventCalendarComponent },
  { path: "privacy-policy", component:  PrivacyPolicyComponent},
  { path: "shipping-policy", component:  ShippingPolicyComponent},
  { path: "refund-and-returns-policy", component:  RefundAndReturnsPolicyComponent},
  {
    path: "login",
    component: LogindetailsComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: "become-a-distributor",
    component: BecomeADistributorComponent,
  },
  {
    path: "testimonials",
    component: TestimonialsComponent,
  },
  {
    path: "bio",
    component: BioComponent,
  },
  {
    path: "scientific-research",
    component: ScientificResearchComponent,
  },
  {
    path: "app",
    component: AppComponent,
  },
  { path: "complete", component: CompleteComponent, canActivate: [AuthGuard] },
  {
    path: "signup",
    component: EmailAddressComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: "join",
    component: ApplicationDeprecatedComponent,
    canActivate: [LoggedInAuthGuard],
  },
  {
    path: "shipping",
    component: ShippingAddressComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
