<div class="container mx-auto text-center m-[4rem]">
   <h2 class="text-2xl font-semibold mb-4 forgot-password-text">Forgot Password</h2>
    
     <p class="text-sm mb-4 forgot-password-text">
      Enter your email address and a password reset email will be sent to you if you have an account 
      
     </p>
     <div class="flex justify-center mb-4">
       <div class="w-full max-w-xs">
         <mat-form-field class="ds-validate w-full" [ngStyle.lt-md]="{'width':100+'%'}" appearance="outline">
          <input name="email" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"  matInput validate noempty="true" placeholder="Enter Your Email Address" email="true">
        </mat-form-field>
       </div>
     </div>
     <div class="flex justify-center">
       <button class="btn text-white w-full max-w-xs get-started-button px-6 py-2 rounded" validate trigger-check="true" type="submit" (success)="emailSentForgotPassword()">Send New Password →</button>
     </div>
   </div>