import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.sass']
})
export class TermsOfServiceComponent implements OnInit {

    
  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };

  
  constructor() { }

  ngOnInit(): void {
  }

}
