<div class="container mx-auto text-center m-[4rem]" id="login">
    <h2 class="text-2xl font-semibold mb-[2rem] login-text">Enter your Email and Password</h2>
    <div class="flex justify-center">
      <div class="w-full max-w-xs">
        <mat-form-field class="ds-validate w-full" [ngStyle.lt-md]="{'width':100+'%'}" appearance="outline">
          <input name="email" [(ngModel)]="userInfo.username" [ngModelOptions]="{standalone: true}"  matInput validate noempty="true" placeholder="Enter Your Email Address">
        </mat-form-field>
      </div>      
    </div>
    <div class="flex justify-center">
        <div class="w-full max-w-xs">
          <mat-form-field class="ds-validate w-full" appearance="outline" [ngStyle.lt-md]="{'width':100+'%'}" >
            <input type="password" [(ngModel)]="userInfo.password"
            [ngModelOptions]="{standalone: true}"  matInput validate noempty="true" placeholder="Enter Your Password">
          </mat-form-field>
          </div>      
      </div>
    <div class="flex justify-center">
      <button class="btn text-white w-full max-w-xs get-started-button px-6 py-2 rounded" validate trigger-check="true" type="submit" (success)="loginFunction()">Login →</button>
    </div>
    <div class="flex justify-center mt-4">
       <a routerLink="/forgot-password" class="forgot-password">Forgot Password</a>
      </div>
  </div>