<section>
    <builder-component [data]="builder.data" [context]="builder.context" model="section" [options]="builder.options"
        *ngIf="!builder.noBuilderPageForUrl" (load)="builder.noBuilderPageForUrl = $event ? false : true"
        (error)="builder.noBuilderPageForUrl = true">
        <div class="spinner"></div>
    </builder-component>
    <!-- App Videos -->
    <div class="hidden" id="video1" (click)="openVideoPlayer('Welcome To Your Avini App', 'https://player.vimeo.com/video/875253733?h=346a20731a')"></div>
    <div class="hidden" id="video2" (click)="openVideoPlayer('Sharing Content', 'https://player.vimeo.com/video/875253402?h=dac3970002')"></div>
    <div class="hidden" id="video3" (click)="openVideoPlayer('Prospects Content', 'https://player.vimeo.com/video/875253219?h=6fe540eb8e')"></div>
    <div class="hidden" id="video4" (click)="openVideoPlayer('Events & Alerts', 'https://player.vimeo.com/video/875252967?h=4fe545e4a6')"></div>

    <!-- FAQs Accordian -->
    <div class="mx-auto max-w-screen-lg px-4 sm:py-8">
        <mat-accordion class="faq-accordion">
            <mat-expansion-panel (opened)="faqExpandedPanels[i] =  true" (closed)="faqExpandedPanels[i] =  false" *ngFor="let panel of faqData; let i = index" [ngClass]="{'expanded-panel': faqExpandedPanels[i]}">
                <mat-expansion-panel-header>
                    <mat-panel-title> {{ panel.title }} </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    {{ panel.content }}
                </p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</section>