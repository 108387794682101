import { Component, OnInit } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { isSameDay } from 'date-fns/isSameDay';
import { CustomDateFormatter } from './custom-date.provider';
import { ProductService } from '../../shared/services/product.service';
import { Router } from '@angular/router';
import { RestApiService } from '../../shared/services/restapi.service';
import { UserService } from '../../shared/services/user.service';

// declare var CommonNinja: any;
@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  providers:[
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class EventCalendarComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {
      categories: this.productService.retailStoreCategories || [],
      webalias: this.user.userServiceModal.WebAlias
    },
    context: {
      // navigate : (category:string)=>{
      //   if(category) {
      //     const formattedCategory = category.replace(/\s+/g, '-').toLowerCase();
      //     this.router.navigate([`/category/${formattedCategory}`]);
      //   } 
      // }
    }
  };

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  today: Date = new Date();
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  events: CalendarEvent[] = [];
  specificDates: { date: Date; content: string,time:string,backgroundColor: string }[] = [
    { date: new Date(2024, 7, 15), content: 'Avini Conference Call', time:'11.00 AM',backgroundColor: '' },
    { date: new Date(2024, 7, 25), content: 'Avini Conference Call', time:'8.00 AM',backgroundColor: '' },
    { date: new Date(2024, 7, 31), content: 'Avini Conference Call', time:'9.00 PM',backgroundColor: '' },
    { date: new Date(2024, 7, 1), content: 'Avini Conference Call', time:'10.00 PM',backgroundColor: '' },
    { date: new Date(2024, 7, 10), content: 'Avini Conference Call', time:'2.00 PM',backgroundColor: '' }
  ];
  randomColors: string[] = [
    'bg-purple-100 text-purple-700',
    'bg-pink-100 text-pink-700',
    'bg-green-100 text-green-700',    
    'bg-blue-100 text-blue-700',
    'bg-yellow-100 text-yellow-700'
  ];
  constructor(private productService: ProductService,
    public router: Router,
    private apiService: RestApiService,
    public user: UserService
  ) {
  }

  ngOnInit(): void {
    this.assignRandomColors();
    this.getAllCategories();
    this.addCalender();
  }

  addCalender() {
    try {
      const s = document.createElement('script');
      const h = document.querySelector('head') || document.body;
      s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
      s.async = true;
      s.onload = function() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
          acsbJS.init({
              statementLink: '',
              footerHtml: '',
              hideMobile: false,
              hideTrigger: false,
              disableBgProcess: false,
              language: 'en',
              position: 'right',
              leadColor: '#146FF8',
              triggerColor: '#146FF8',
              triggerRadius: '50%',
              triggerPositionX: 'right',
              triggerPositionY: 'bottom',
              triggerIcon: 'people',
              triggerSize: 'bottom',
              triggerOffsetX: 20,
              triggerOffsetY: 20,
              mobile: {
                  triggerSize: 'small',
                  triggerPositionX: 'right',
                  triggerPositionY: 'bottom',
                  triggerOffsetX: 10,
                  triggerOffsetY: 10,
                  triggerRadius: '20'
              }
          });
      };
      h.appendChild(s);
    } catch (error) {
      console.error("error while adding calender", error);
    }
   
  }

  getTodayDayName(): string {
    return this.days[this.today.getDay()];
  }

  assignRandomColors(): void {
    this.specificDates.forEach((specificDate, index) => {
      specificDate.backgroundColor = this.randomColors[index % this.randomColors.length];
    });
  }
  // Method to check if the given date matches any of the specific dates
  getSpecificDateContent(date: Date) {
    return this.specificDates.find(sd => isSameDay(sd.date, date));
  }

  getAllCategories() {
    if (this.productService.retailStoreCategories.length > 0) {
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
      return this.productService.retailStoreCategories;
    }
    
    this.apiService.GetCategories(3).subscribe((result) => {
      this.productService.retailStoreCategories = result;
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
    });
  }

  getImageUrlFromDescription(categories) {

    // Insert Cover Iamge
    categories.forEach(element => {
    
      element.providerDescription = "";
      const translationsArray = element.CategoryTranslations || [];
      const englishTranslation = translationsArray.find((t) => t.LanguageCode == "en");
      const { Description } = englishTranslation || {};
      // Description = Description?.split("|") || [];
      element.catImage = `https://avinihealth.corpadmin.directscale.com/CMS/Images/CategoryImages/${element.ID}.jpg` || "https://avinihealth.corpadmin.directscale.com/CMS/Images/Inventory/no_image.jpg";
      element.providerDescription = Description;
    });

    this.productService.retailStoreCategories = categories;
    
    // Name Modifications
    categories.forEach((category) => {
      if(category?.Name?.toLowerCase() === "zeolite") {
        category.Name = "Cell Defender";
      }
    });

    // Filter Out these 4 properties
    const hideCategories = {
      11: "Sales Tools & Accessories",
      12: "Travel Packets",
    };

    categories = categories.filter((cat) => !Object.prototype.hasOwnProperty.call(hideCategories, cat.ID));

    this.builder.data = this.productService.retailStoreCategories;
    this.builder.data['categories'] = this.productService.retailStoreCategories;
    this.builder.data['webalias'] = this.user.userServiceModal.WebAlias;
  }
  initializeCommonNinja(): void {
    // if (typeof CommonNinja !== 'undefined') {
      // CommonNinja.load({
      //   widgetId: '9e259bdd-85a3-44cd-8cdf-1e3976af9d85' 
      // });
    // }
  }
  ngAfterViewInit(): void {
    this.initializeCommonNinja();
  }
}
