<div class="container mx-auto text-center m-[4rem]">
 <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c49f2f293b7df500618ddaa57fcd25ca884111c8b038729af2b274d5e38a9e08?apiKey=9d06630e091845a9863c65f5c77fcc83&&apiKey=9d06630e091845a9863c65f5c77fcc83&width=1600" alt="Avini Health" class="mb-6 mx-auto" style="max-width: 100%;">
  <h2 class="text-2xl font-semibold mb-4 welcome-text">Welcome</h2>
  <p class="text-lg mb-2">Enter the referral code of the advocate who referred you:</p>
  <p class="text-sm mb-4 welcome-text">
    If you do not have a referral code, please e-mail your name, surname and contact details to
    <a href="mailto:support@avinihealth.com" class="support-mail">support@avinihealth.com</a>
  </p>
  <div class="flex justify-center mb-4">
    <div class="w-full max-w-xs">
      <input type="text" class="form-input w-full px-4 py-2 border border-gray-300 rounded" placeholder="Enroller’s Referral Code">
    </div>
  </div>
  <div class="flex justify-center">
    <button class="btn text-white w-full max-w-xs get-started-button px-6 py-2 rounded">Get Started →</button>
  </div>
</div>