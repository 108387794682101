import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.scss"],
})
export class ContactComponent implements OnInit {
  
  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };

  constructor(
  ) {}

  ngOnInit() {
  }

}
