import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/pages/home/home.component";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    pathMatch: "full",
  },
  {
    path: "enrollment",
    loadChildren: () =>
      import("./modules/enrollment/enrollment.module").then(
        (m) => m.EnrollmentModule,
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account-details/account-details.module").then(
        (m) => m.AccountDetailsModule,
      ),
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      relativeLinkResolution: "legacy",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
