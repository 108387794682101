<div class="w-full">
    <div class="flex px-4 py-5 flex-row justify-between items-center border-b border-slate-200">
        <div class="text-slate-700 text-base" [textContent]="title"></div>
        <svg (click)="closePopup()" class="w-6 cursor-pointer h-6 text-gray-800" aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M6 18 17.94 6M18 18 6.06 6" />
        </svg>
    </div>
    <div class="w-full">
        <iframe class="sm:min-h-[500px]" [src]="videoUrl" width="100%" height="auto" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>