<div class="container mx-auto text-start m-[4rem]">
    <div class="terms-header mb-[2rem]">
       <h1 class="text-3xl">Accept Avini Health Terms & Conditions</h1> 
    </div>
    <div class="terms-body">
        <h5> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum architecto eveniet vitae blanditiis! Eaque aperiam blanditiis eveniet quod impedit. Quis, nobis! Ut hic error dignissimos architecto reiciendis nihil illo cum. Impedit vero in, voluptatem quia aliquam nihil nisi nemo voluptate veritatis temporibus id culpa quam accusamus dolorem? Quia quae, quaerat sunt aut tempora doloribus quos est perferendis ipsa similique cupiditate quibusdam totam aliquid eum delectus aspernatur nemo voluptate nobis consequatur alias laudantium. Odit quaerat asperiores itaque iusto deserunt! Harum facere, placeat voluptatum alias cupiditate sunt eius quis nostrum necessitatibus, eum ipsum, libero dolor velit ut nulla nihil natus fugiat perferendis.
        </h5><br>
        <h5>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est in amet aliquam quis deleniti soluta facere iste error accusantium! Obcaecati quisquam quae in magni dignissimos dolor, voluptatem aliquid repellat, placeat mollitia consequuntur saepe eum ipsum eius animi iure omnis facere, hic nostrum minus. Deserunt a consectetur quaerat totam provident excepturi similique maiores cupiditate assumenda harum aliquam, impedit expedita ullam maxime sunt, saepe eius labore, soluta asperiores aut perferendis possimus dicta sequi! Accusamus vero laborum repudiandae repellendus animi quidem accusantium at! Commodi dicta perspiciatis tempora. A enim quo autem nihil, quae neque ratione quia rem, adipisci inventore deserunt. Eius, vel eligendi.
        </h5><br>
        <h5> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum architecto eveniet vitae blanditiis! Eaque aperiam blanditiis eveniet quod impedit. Quis, nobis! Ut hic error dignissimos architecto reiciendis nihil illo cum. Impedit vero in, voluptatem quia aliquam nihil nisi nemo voluptate veritatis temporibus id culpa quam accusamus dolorem? Quia quae, quaerat sunt aut tempora doloribus quos est perferendis ipsa similique cupiditate quibusdam totam aliquid eum delectus aspernatur nemo voluptate nobis consequatur alias laudantium. Odit quaerat asperiores itaque iusto deserunt! Harum facere, placeat voluptatum alias cupiditate sunt eius quis nostrum necessitatibus, eum ipsum, libero dolor velit ut nulla nihil natus fugiat perferendis.
        </h5><br>
        <h5>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est in amet aliquam quis deleniti soluta facere iste error accusantium! Obcaecati quisquam quae in magni dignissimos dolor, voluptatem aliquid repellat, placeat mollitia consequuntur saepe eum ipsum eius animi iure omnis facere, hic nostrum minus. Deserunt a consectetur quaerat totam provident excepturi similique maiores cupiditate assumenda harum aliquam, impedit expedita ullam maxime sunt, saepe eius labore, soluta asperiores aut perferendis possimus dicta sequi! Accusamus vero laborum repudiandae repellendus animi quidem accusantium at! Commodi dicta perspiciatis tempora. A enim quo autem nihil, quae neque ratione quia rem, adipisci inventore deserunt. Eius, vel eligendi.
        </h5><br>
        <h5> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum architecto eveniet vitae blanditiis! Eaque aperiam blanditiis eveniet quod impedit. Quis, nobis! Ut hic error dignissimos architecto reiciendis nihil illo cum. Impedit vero in, voluptatem quia aliquam nihil nisi nemo voluptate veritatis temporibus id culpa quam accusamus dolorem? Quia quae, quaerat sunt aut tempora doloribus quos est perferendis ipsa similique cupiditate quibusdam totam aliquid eum delectus aspernatur nemo voluptate nobis consequatur alias laudantium. Odit quaerat asperiores itaque iusto deserunt! Harum facere, placeat voluptatum alias cupiditate sunt eius quis nostrum necessitatibus, eum ipsum, libero dolor velit ut nulla nihil natus fugiat perferendis.
        </h5>
           </div>
           <div class="flex justify-center mt-6">
            <button class="btn  w-full max-w-xs Accept-button px-6 py-2 rounded">Accept & Continue →</button>
          </div>
</div>