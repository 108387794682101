import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductService } from "src/app/components/shared/services/product.service";
import { RestApiService } from "src/app/components/shared/services/restapi.service";
import { UserService } from "src/app/components/shared/services/user.service";
import { productCategoryStoreId, productPriceGroup, IMAGE_URL } from "src/constants";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-productNew",
  templateUrl: "./productNew.component.html",
  styleUrls: ["./productNew.component.scss"],
})
export class ProductNewComponent implements OnInit {
  productList: any[] = [];
  savedProductList: any[] = [];
  selectedCategoryId: string;
  selectedCategory: any;
  imageUrl: string;
  categories: any[] = [];
  sortOption: string = '';
  minPrice: number;
  maxPrice: number;
  isLoading: boolean;
  urlCategoryName: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private userService: UserService,
    public apiService: RestApiService,
  ) { }

  ngOnInit() {
    this.imageUrl = environment.imageUrl;
  
    this.handleCategories().then(() => {
      this.activatedRoute.paramMap.subscribe(params => {
        this.urlCategoryName = params.get('category');
        let decryptedCategory = this.urlCategoryName.replace(/-/g, ' ').toLowerCase();
        let newCategoryId;
        if(decryptedCategory?.toLowerCase() === "cell defender" || decryptedCategory?.toLowerCase() === "zeolite") {
          newCategoryId = 5;
        }
        else
        {
          newCategoryId = this.productService.retailStoreCategories.find(x => (x.Name).toLowerCase() === decryptedCategory)?.ID
        }
        if (newCategoryId && newCategoryId !== this.selectedCategoryId) {
          this.selectedCategoryId = newCategoryId;
          this.updateCategoryAndProducts();
        }
      });
    });
  }

  async handleCategories() {
    try {
      this.categories = await this.getAllCategories();
      if (this.selectedCategoryId) {
        this.selectedCategory = this.categories.find(x => x.ID === parseInt(this.selectedCategoryId));
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  getAllCategories(): Promise<any[]> {
    if (this.productService.retailStoreCategories.length > 0) {
      return Promise.resolve(this.productService.retailStoreCategories);
    }
  
    return new Promise((resolve, reject) => {
      this.apiService.GetCategories(productCategoryStoreId).subscribe(
        (result) => resolve(result),
        (error) => reject(error)
      );
    });
  }

  fetchProducts(sortOption?: string) {
    this.isLoading = true;
    this.productService
      .getProductByCategory({
        CategoryId: parseInt(this.selectedCategoryId),
        PriceGroup: productPriceGroup,
        StoreID: productCategoryStoreId,
      })
      .subscribe(
        (products) => {
          this.isLoading = false;
          if (products.length > 0) {
            this.productList = products;
            this.savedProductList = products;
            if (sortOption) {
              this.sortProducts(sortOption);
            }
            this.applyPriceFilter();
          }
        }
      );
  }

  applyPriceFilter() {
    if (this.minPrice != null || this.maxPrice != null) {
      this.productList = this.savedProductList.filter(product => {
        return (this.minPrice == null || product.Price >= this.minPrice) &&
               (this.maxPrice == null || product.Price <= this.maxPrice);
      });
    }
    else{
      this.productList = this.savedProductList;
    }
  }


  sortProducts(option: string) {
    if (option === 'sort-asc-name') {
      this.productList.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
    } else if (option === 'sort-desc-name') {
      this.productList.sort((a, b) => b.ProductName.localeCompare(a.ProductName));
    } else if (option === 'sort-asc-price') {
      this.productList.sort((a, b) => a.Price - b.Price);
    } else if (option === 'sort-desc-price') {
      this.productList.sort((a, b) => b.Price - a.Price);
    }
  }

  onSortChange(event: any) {
    let selectedOption = event.target.value;
    this.sortOption = selectedOption;
    this.fetchProducts(selectedOption);
  }

  onCategoryChange(category: string) {
    const formattedCategory = category.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate([`/category/${formattedCategory}`]).then(() => {
      // this.updateCategoryAndProducts()
    });
  }

  updateCategoryAndProducts() {
    if (this.categories) {
      this.selectedCategory = this.categories.find(x => x.ID === parseInt(this.selectedCategoryId));
      if (this.selectedCategory || 'all') {
        this.fetchProducts(this.sortOption);
      } else {
        console.error('Selected category not found:', this.selectedCategoryId);
      }
    } else {
      console.error('Categories are not loaded.');
    }
  }
  navigateToProductDetails(product: any) {
    const formattedProductUrl = `${this.urlCategoryName}/avini-health-${product.ProductName.replace(/\s+/g, '-').toLowerCase()}-sku${product.SKU}-id${product.ItemID}`;
    this.router.navigate([`product/${formattedProductUrl}`]);
  }
}
