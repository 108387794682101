import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CartItem } from "src/app/modals/cart-item";
import { CartService } from "../services/cart.service";
import { AppSettings, Settings } from "../services/color-option.service";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "../services/account.service";
import { UserService } from "../services/user.service";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService } from "../services/config.service";
import { RestApiService } from "../services/restapi.service";
import { CompanyService } from "../services/company.service";
import { HttpClient } from "@angular/common/http";
import { ItemsListService } from "../services/itemsList.service";
import { ProductService } from "../services/product.service";
import { Cart1Service } from "../services/cart1.service";
import { ViewportScroller } from "@angular/common";
import { productCategoryStoreId } from "src/constants";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public sidenavMenuItems;
  @Output() sildeMobileNavEvent = new EventEmitter<any>();
  public currencies = ["USD", "EUR"];
  public currency;
  public flag;
  public userService: UserServiceModal;
  userServiceModal: UserServiceModal;
  products: Array<DSProduct>;
  welcmtext: string = "";
  public allItems: { Category: string; CategoryId: string|number }[] = [];
  public productlist: { Category: string; CategoryId: string|number }[] = [];
  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;
  isTop: boolean = true;
  currentRouterPath;
  HomeProductlist;
  public allowedCountries = [];
  private authSubscription: Subscription;
  selectedCountry = "us";
  backOfficeUrl: string;

  mobileSideMenus = [
    { title: "My Account", icon: "account", expandedAllowed: true, expandedState: false,  expandedItems: 
      [] 
    },
    { title: "Home", icon: "", expandedAllowed: false, path: '/home' },
    { title: "Products", icon: "", expandedAllowed: true, expandedState: false, expandedItems: 
      []
    },
    { title: "Testimonials", icon: "", expandedAllowed: false, path: '/testimonials' },
    { title: "Scientific Research", icon: "", expandedAllowed: false, path: '/scientific-research' },
    { title: "Event Calendar", icon: "", expandedAllowed: false, path: '/event-calendar'},
    { title: "App", icon: "", expandedAllowed: false, path: '/app' },
    { title: "Contact Us", icon: "", expandedAllowed: false, path: '/contact' },
    { title: "Join Now", icon: "", expandedAllowed: false, path:'/enrollment/welcome' },
    { title: "Back Office", icon: "", expandedAllowed: false, externalLink: 'https://avinihealth.office2.directscale.com/#/Login' },
  ];

  constructor(
    public accountService: AccountService,
    private cartService: CartService,
    public appSettings: AppSettings,
    public translate: TranslateService,
    public user: UserService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public configService: ConfigService,
    public apiService: RestApiService,
    public companyService: CompanyService,
    public http: HttpClient,
    public itemsListService: ItemsListService,
    public productsService: ProductService,
    public UserService: UserService,
    public cart1Service: Cart1Service,
    private viewportScroller: ViewportScroller
  ) {
    this.allowedCountries = JSON.parse(
      sessionStorage.getItem("allowedCountries") || '[]'
    );
    this.userServiceModal = this.UserService?.userServiceModal;
    this.settings = this.appSettings.settings;
    this.userService = this.user.userServiceModal;
    this.cartService
      .getItems()
      .subscribe(
        (shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems),
      );
    this.router.events.subscribe(() => {
      this.currentRouterPath = router.url;
    });
    this.countryInitialization();
  }

  countryInitialization(){
    const selectedCountry = sessionStorage.getItem('selectedCountry') === 'undefined' ? null : sessionStorage.getItem('selectedCountry');
    this.selectedCountry = (selectedCountry || 'US').toLowerCase();
  }

  run() {
    this.allItems = this.itemsListService.categoryList;
    const product = this.itemsListService.groupByKeepOrder(
      this.productsService.orders,
      "CategoryId",
    );
    let uniqueRequireProduct = [];
    if (this.configService.localSettings.Global.CategoriesToFetch?.length > 0) {
      product.filter((x) => {
        x.filter((item) => {
          if (
            this.configService.localSettings.Global.CategoriesToFetch.indexOf(
              item.Category,
            ) > -1
          ) {
            uniqueRequireProduct.push(item);
          }
        });
      });
    } else {
      uniqueRequireProduct = [...product];
    }
    this.HomeProductlist = uniqueRequireProduct;
    const note: HTMLElement = document.querySelector(
      "." + document.getElementById("header-menu").parentElement.className,
    );
    note.style.width = "900px";
    note.style.height = "250px";
    if (screen.width < 1225) {
      note.style.width = "622px";
      note.style.height = "250px";
    }
  }

  ngOnInit() {
    this.getAllCategories();
    this.currency = this.currencies[0];
    setTimeout(() => {
      this.welcmtext = this.translate.instant("welcome_");
    }, 3000);
    this.updateMobileMenuBasedOnLoginStatus();
    this.authSubscription = this.accountService.user.subscribe(user => {
      this.updateMobileMenuBasedOnLoginStatus();
    });
    this.backOfficeUrl = environment.backOfficeUrl;
  }
  updateMobileMenuBasedOnLoginStatus() {
    const accountMenu = { 
      title: "My Account", 
      icon: "account", 
      expandedAllowed: true, 
      expandedState: false, 
      expandedItems: this.isLoggedIn() ? [
        { title: "Log Out", icon: "", path: '/home', onClick: () => this.logout() }
      ] : [
        { title: "Sign In", icon: "", path: '/login' },
        { title: "Sign Up", icon: "", path: '/signup' }
      ] 
    };
  
    const joinNowMenu = { 
      title: "Join Now", 
      icon: "", 
      expandedAllowed: false, 
      path: '/enrollment/welcome' 
    };
  
    this.mobileSideMenus = this.mobileSideMenus.filter(menu => menu.title !== "My Account" && menu.title !== "Join Now");
  
    this.mobileSideMenus.unshift(accountMenu);
  
    if (!this.isLoggedIn()) {
      this.mobileSideMenus.push(joinNowMenu);
    }
  }
  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
  toggleMobileMenu($event) {
    this.sildeMobileNavEvent.emit($event);
  }
  changeCurrency(currency) {
    this.currency = currency;
  }
  changeLang(flag) {
    this.flag = flag;
  }
  logout() {
    this.accountService.logout();
  }

  login() {
    this.router.navigateByUrl("/login");
  }
  @HostListener("window:scroll", ["$event"])
  onScroll(): void {
    if (window.scrollY > 60) {
      this.isTop = false;
    } else {
      this.isTop = true;
    }
  }

  isLoggedIn() {
    if (
      this.userService?.customerData &&
      Object.keys(this.userService?.customerData).length &&
      this.userService.customerData.CustomerId &&
      this.user.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }

  selectFlagCountry() {
    this.configService.OpenLanguageSelector.emit(true);
  }
  
  public updatecountry(country, languagecode?) {
    this.cart1Service.updateCountry(country, languagecode, true, false);
  }

  scrollToEventsSection() {
    this.router.navigate(['/home'], { fragment: 'events-section' }).then(() => {
      const anchorElement = document.querySelector(`.events-section`);
      anchorElement && anchorElement.scrollIntoView({ behavior: 'smooth' });
    });
  }

  closeAllExpandedMenus() {
    this.mobileSideMenus.forEach((menu) => {
      menu && menu.expandedState && (menu.expandedState = false);
    });
  }
  getAllCategories() {
    if (this.productsService.retailStoreCategories.length > 0) {
      this.updateProductsMenu();
      return this.productsService.retailStoreCategories;
    }

    this.apiService.GetCategories(productCategoryStoreId).subscribe((result) => {
      this.productsService.retailStoreCategories = result;
      this.updateProductsMenu();
    });
  }
  updateProductsMenu() {
    const productsMenu = this.mobileSideMenus.find(menu => menu.title === "Products");

    if (productsMenu) {
      productsMenu.expandedItems = this.productsService.retailStoreCategories?.map(category => ({
        title: category.Name,
        id: category.ID,
        icon: ""
      }));
    }
  }

  navigateToCategory(category: string) {
    const formattedCategory = category.replace(/\s+/g, '-').toLowerCase();
    this.router.navigate([`/category/${formattedCategory}`]);
  }
  navigate(url: string) {
    this.router.navigate([`/${url}`]);
    console.log(url);
  }
}
