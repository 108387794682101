import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../../shared/services/restapi.service';
import { NotificationService } from '../../shared/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email = "";
  constructor(
    public notificationService: NotificationService,
    public apiServie: RestApiService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  emailSentForgotPassword() {
    // this.loadingDetails = true;
    // this.forgotpasswordtype = this.selectedForgotPasswordOption.option;
    const forgotPasswordRequest = {
      value: this.email,
      valType: 2,
    };
    this.apiServie
      .triggerForgotPasswordProcess(forgotPasswordRequest)
      .subscribe(
        (res) => {
          // console.log(res);
          
          // this.loadingDetails = false;
          this.email = "";
          // this.sentEmail = true;
          this.notificationService.success("Success", "Please check your Email inbox");
          this.router.navigateByUrl("/login");
        },
        () => {
          // this.loadingDetails = false;
          this.notificationService.error("Error", "some_error_occur_try_again");
        },
        () => {},
      );
  }
}
