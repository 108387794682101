import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  title: string = "";
  videoUrl: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<VideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer
   ) {
    this.title = data.title;
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);
  }

  ngOnInit(): void {
  }

  closePopup() {
    this.dialogRef.close();
  }

}
