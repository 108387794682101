<div class="container mx-auto text-center m-[4rem]">
  <h2 class="text-2xl font-semibold mb-[2rem] Signup-Information-text">Enter Information Below</h2>
  <p class="text-lg mb-4 signup-text">
      support@avinihealth.com
  </p>
  <div class="different-email mb-4">
      <a href="" class="change-email">Use a Different Email</a>
  </div>
  <p class="text-base mb-6 address-text">
      You are shopping with Micheal Polak (michaelpolak) - 
      <a href="" class="change-address">Change</a>
  </p>
  <form>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="First Name">
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="Last Name">
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="password" class="mt-1 p-2 w-full border rounded-md" placeholder="Create Password">
          <p class="text-sm text-gray-600 mt-1 text-start">Password must be 8 characters 1 uppercase 1 number</p>
      </div>
      <div class="w-1/2 mx-auto">
        <div class="relative flex items-center mb-4">
          <h3 class="text-lg font-semibold flex-1">Shipping Address</h3>
          <hr class="absolute inset-0 border-t border-gray-300" style="top: 50%; left: 25%; width: 75%; transform: translateY(-50%);">
        </div>
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <select class="mt-1 p-2 w-full border rounded-md">
              <option>United States</option>
          </select>
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="Address Line 1">
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="Address Line 2">
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="City">
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <select class="mt-1 p-2 w-full border rounded-md">
              <option>Select State</option>
          </select>
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="Zip Code">
      </div>
      <div class="w-1/2 mx-auto">
        <div class="relative flex items-center mb-4">
          <h3 class="text-lg font-semibold flex-1">Billing Address</h3>
          <hr class="absolute inset-0 border-t border-gray-300" style="top: 50%; left: 25%; width: 75%; transform: translateY(-50%);">
        </div>
      </div>
      <div class="mb-4 w-1/2 mx-auto">
          <input type="text" class="mt-1 p-2 w-full border rounded-md" placeholder="Phone">
          <p class="text-sm text-gray-600 mt-1 text-start">Please include your country code (+XXX) with your phone number</p>
      </div>
     
      <div class="text-center mt-4">
          <p class="signup-form-text">You are shopping with Michael Polak (michaelpolak) - <a href="#" class="change-address">Change</a></p>
          <div class="text-center mx-auto mt-6">
            <button class="btn text-white w-1/2 max-w-xs get-started-button px-6 py-2 rounded">
                Continue →
            </button>
        </div>
          <p class="mt-2 signup-form-text">Have an account? <a href="#" class="change-address">Sign In</a></p>
      </div>
  </form>
</div>
