<div fxLayout="column">
    <!-- date -->
    <mat-form-field class="mb-0">
        <input matInput [(ngModel)]="autoshipDate" [matDatepicker]="datepicker" [matDatepickerFilter]="dateFilter" placeholder="Change Date" readonly class="focus:outline-none focus-visible:outline-none">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker [startAt]="autoshipDate"></mat-datepicker>
    </mat-form-field>

    <span class="mb-4">
        <span class="font-bold">Date: </span>
        <span [textContent]="autoshipDate | date: 'MMMM d, yyyy'"></span>
    </span>
    

    <!-- buttons -->
    <div fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <button mat-raised-button color="primary" class="w-full" (click)="setDate()">Set New Date</button>
        <button class="text-primary px-3 w-full py-1 border rounded-md" (click)="dialogRef.close()">Discard</button>
    </div>
</div>
