import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { RestApiService } from '../../shared/services/restapi.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../shared/services/account.service';
import { OrderService } from '../../shared/services/order.service';
import { Cart1Service } from '../../shared/services/cart1.service';
import { NotificationService } from '../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../shared/services/product.service';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from '../../shared/services/utility.service';
import { AutoshipConfigurationService } from '../../shared/services/autoshipConfiguration.service';
import { ConfigService } from '../../shared/services/config.service';
import { UserServiceModal } from 'src/app/modals/userservice.modal';

@Component({
  selector: 'app-logindetails',
  templateUrl: './logindetails.component.html',
  styleUrls: ['./logindetails.component.scss']
})
export class LogindetailsComponent implements OnInit {
  public userInfo = {
    username: "",
    password: ""
  };
  userService: UserServiceModal;
  previousType;
  constructor(
    private titleService: Title,
    private router: Router,
    private accountService: AccountService,
    public user: UserService,
    private route: ActivatedRoute,
    public orderService: OrderService,
    public cartsService: Cart1Service,
    public notificationService: NotificationService,
    public translate: TranslateService,
    public itemsService: ProductService,
    public apiService: RestApiService,
    private cookieService: CookieService,
    public utilityService: UtilityService,
    public autoshipConfigurationService: AutoshipConfigurationService,
    public configService: ConfigService,
  ) { 
    this.userService = user.userServiceModal;
    this.cookieService.deleteAll("/");
  }

  ngOnInit(): void {
    this.previousType = this.userService.customerTypeID;
  }
  
  loginFunction(username?: string, password?: string) {
    localStorage.setItem("guestLogin", "false");
    this.user.clearCustomerData();
    localStorage.removeItem("guestLogin");
    const loginrequest = {
      granttype: "password",
      scope: "office",
      username: username || this.userInfo.username,
      password: password || this.userInfo.password,
    };
    this.apiService.Login(loginrequest).subscribe(
      (result) => {
        if (result && result.status == 200 && result.body.Status != 1) {
          try {
            localStorage.setItem("isLoggedIn", "true");
            this.accountService
              .getCustomerData(result.body.CustomerId)
              .then((data: any) => {
                if (data && data.Data) {


                  //  Clear cart
                  this.cartsService.clearCart();

                  // getting userdata
                  localStorage.setItem("user", JSON.stringify(data.Data));
                  this.userService.customerData = data.Data;
                  localStorage.setItem(
                    "userService",
                    JSON.stringify(this.userService),
                  );
                  this.userService.customerTypeID =
                    this.userService.customerData.CustomerType;
                  if (this.previousType !== this.userService.customerTypeID) {
                    this.userService.couponInfo.Allcoupons = [];
                    this.autoshipConfigurationService.autoshipDate = "";
                    this.userService.shippingMethods = [];
                    this.userService.selectedShippingMethod = 0;
                    this.autoshipConfigurationService.init();
                  }
                  if (
                    !this.utilityService.isEmptyObject(
                      this.userService.customerData.DefaultShippingAddress,
                    )
                  ) {
                    this.user.setShippingAddress();
                  } else {
                    this.userService.shippingAddress =
                      new UserServiceModal().newshippingAddress;
                  }
                  this.accountService.userSubject.next(data.Data);
                  if (this.userService.customerData.WebAlias) {
                    this.apiService
                      .validateWebAlias(this.userService.customerData.WebAlias)
                      .subscribe(
                        (res: any) => {
                          if (res.Data) {
                            // calling redirect function
                            this.userService.WebAlias =
                              this.userService.customerData.WebAlias;
                            this.loginredirect(this.userService.WebAlias);
                          }
                        },
                        (error) => {
                          if (error.Data && !error.Data.WebAlias) {
                            this.notificationService.error(
                              "error_",
                              "webalias_not_exists",
                            );
                            return false;
                          }
                        },
                        () => {},
                      );
                  } else if (this.userService.customerData.SponsorId) {
                    this.apiService
                      .getSearchCustomerDetail(
                        this.userService.customerData.SponsorId,
                      )
                      .subscribe(
                        (resp: any) => {
                          if (resp.Data) {
                            this.apiService
                              .validateWebAlias(resp.Data.WebAlias)
                              .subscribe(
                                (res: any) => {
                                  if (res.Data) {
                                    // calling redirect function
                                    this.userService.WebAlias =
                                      resp.Data.WebAlias;
                                    this.loginredirect(
                                      this.userService.WebAlias,
                                    );
                                  }
                                },
                                (error) => {
                                  if (error.Data && !error.Data) {
                                    this.notificationService.error(
                                      "error_",
                                      "webalias_not_exists",
                                    );
                                    return false;
                                  }
                                },
                                () => {},
                              );
                          }
                        },
                        (error) => {
                          if (error.Data && !error.Data.WebAlias) {
                            this.notificationService.error(
                              "error_",
                              "webalias_not_exists",
                            );
                            return false;
                          }
                        },
                        () => {},
                      );
                  }
                } else {
                  this.notificationService.error(
                    "error_",
                    "error_occured_try_again",
                  );
                }
              })
              .catch((error) => {
                this.notificationService.error(
                  "error_",
                  "error_occured_try_again",
                );
                console.error("ex", error);
              });
          } catch (successEx) {
            this.notificationService.error("error_", "error_occured_try_again");
            console.error("ex", successEx);
          }
        } else {
          this.notificationService.error("error_", "Authentication Failed");
        }
      },
      () => {
        this.notificationService.error("error_", "error_occured_try_again");
      },
      () => {},
    );
  }

  private loginredirect(alias) {
    let newUrl: string;
    if (this.checkitem()) {
      this.userService.checkItems = true;
      newUrl = `${location.origin}/${alias}/checkout`;
    } else if (this.itemsService.selectedAutoOrderItems.length) {
      this.userService.checkItems = true;
      newUrl = `${location.origin}/${alias}/home`;
    } else {
      newUrl = `${location.origin}/${alias}/home`;
    }
    location.href = newUrl;
  }

  checkitem() {
    return (
      this.itemsService.selectedOrderItems.length > 0 ||
      this.itemsService.selectedAutoOrderItems.length > 0
    );
  }
}
