import {
  ApplicationInitStatus,
  APP_INITIALIZER,
  Component,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "./components/shared/services/config.service";
import { UserService } from "./components/shared/services/user.service";
import { PersistentService } from "./components/shared/services/persistent.service";
import { MatDialog } from "@angular/material/dialog";
import { MarketSelectorComponent } from "./components/shared/model/market-selector/market-selector.component";
import { environment } from "src/environments/environment";
import { Cart1Service } from "./components/shared/services/cart1.service";
import { ProductService } from "./components/shared/services/product.service";
import { OrderService } from "./components/shared/services/order.service";
import { RestApiService } from "./components/shared/services/restapi.service";
import { AccountService } from "./components/shared/services/account.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(APP_INITIALIZER) public appInit: ApplicationInitStatus,
    public configService: ConfigService,
    public router: Router,
    private translate: TranslateService,
    public userService: UserService,
    public persistent: PersistentService,
    private dialog: MatDialog,
    private cart1Service: Cart1Service,
    private activateroute: ActivatedRoute,
    private productService: ProductService,
    private orderService: OrderService,
    private apiService: RestApiService,
    private accountService: AccountService
  ) {
    if (sessionStorage.getItem("language")) {
      translate.setDefaultLang(sessionStorage.getItem("language"));
      translate.use(sessionStorage.getItem("language"));
    } else {
      translate.setDefaultLang("en");
      translate.use("en");
    }
  }
  @HostListener("window:beforeunload", ["$event"]) unloadHandler() {
    localStorage.setItem(
      "userService",
      JSON.stringify(this.userService.userServiceModal),
    );
    localStorage.setItem(
      "retailData",
      JSON.stringify(this.persistent.retailData),
    );
  }

  ngOnInit() {
    // show market selector according to page
    this.router.events.subscribe((event: RouterEvent) => {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      if (event.url == "/join") {
        //this.showMarketSelector();
      }
    });

    /* ------------------------------------------------
      Cart Link Sharing Process
    ------------------------------------------------ */

    this.activateroute.queryParams.subscribe((queryparam) => {
      const { isshort, type, countrycode, language, cartitems } = queryparam;
      if(isshort && cartitems) {
        this.handleCartLinkSharing(type, countrycode, language, cartitems);
      }
    });

    /* ------------------------------------------------
      Get Customer Reward Points
    ------------------------------------------------ */

    if(this.accountService.isLoggedIn() && this.userService.userServiceModal.customerData?.rewardPointBalance === undefined) {
      this.apiService.GetCustomerRewardPoints()
      .subscribe((result: any) => {
        const { Data } = result;
        if(typeof Data == "number") {
          this.userService.userServiceModal.customerData['rewardPointBalance'] = Data;
        }
      });
    }

    /* ------------------------------------------------
        Page View Event Link Tracking Starts
    ------------------------------------------------ */

    if (environment.linkTracking.isAllowed) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        try {
          const pathArray = evt.urlAfterRedirects.split("/");
          const pageName = pathArray[pathArray.length - 1];
          const data = JSON.stringify({
            pageName: pageName,
            pageURL: location.href,
          });
          window["ZTM"].setStats(
            environment.linkTracking.companyName,
            "PageView",
            data,
          );
        } catch (error) {
          //
        }
        window.scrollTo(0, 0);
      });
    }

    /*---------x---------Page View Event Link Tracking Ends----------x----------  */
  }

  showMarketSelector() {
    if (!(sessionStorage.getItem("IsMarketCountrySelected") === "true")) {
      this.dialog.open(MarketSelectorComponent, {
        maxWidth: "400px",
        data: {},
        panelClass: "marketselector-dialog-container",
        disableClose: true,
        autoFocus: false,
      });
    }
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  handleCartLinkSharing(customerType, countryCode, languageCode, cartItems) {

    try {
      
      this.cart1Service.clearCart();
      const queryItems = JSON.parse(cartItems) || [];

      if(queryItems.length == 0) {
        return;
      }

      languageCode =  languageCode.split('-')[0];
      this.configService.setSelectedCountry(countryCode, languageCode);

      sessionStorage.removeItem('items');
      this.productService.getProductByCategory({
        PriceGroup: customerType,
        LanguageCode: languageCode,
        StoreID: 4
      }, false).subscribe(
        (ItemsArray) => {

          const orderItems = [];
          const autoOrderItems = [];

          queryItems.forEach((QI) => {
            ItemsArray.forEach((item) => {
              if(QI.itemcode == item.ItemID) {
                if(QI.orderqty > 0) {
                  item.Quantity = QI.orderqty;
                  orderItems.push({...item});
                }
                if(item.AllowAutoship && QI.autoshipqty > 0) {
                  item.Quantity = QI.autoshipqty;
                  autoOrderItems.push({...item});
                }
              } else {
                // Option Items
              }
            });
          });

          this.productService.selectedOrderItems = orderItems;
          this.productService.selectedAutoOrderItems = autoOrderItems;
          localStorage.setItem('cart.order', JSON.stringify(this.productService.selectedOrderItems));
          localStorage.setItem('cart.autoship', JSON.stringify(this.productService.selectedAutoOrderItems));

          if((orderItems.length + autoOrderItems.length) > 0) {

            // orderQuantity Modal Update
            this.productService.selectedOrderItems.forEach((item) => {
              this.cart1Service.orderQuantity[item.ItemID] = item.Quantity;
            });

            // autoshipQuantity Modal Update
            this.productService.selectedAutoOrderItems.forEach((item) => {
              this.cart1Service.autoshipQuantity[item.ItemID] = item.Quantity;
            });

            this.userService.userServiceModal.customerTypeID = customerType;
            this.router.navigate(['/'], { queryParamsHandling: "" });
          }

          this.productService.selectedOrderItems.length > 0 ? this.orderService.calculateOrder() : this.orderService.calculateOrderResponse = {};
          this.productService.selectedAutoOrderItems.length > 0 ? this.orderService.calculateAutoOrder() : this.orderService.calculateAutoOrderResponse = {};

        }
      );

    } catch (error) {
      //
    }

  }

}

