<builder-component 
  [data]="builder.data"
  [context]="builder.context"
  model="section" [options]="builder.options"
  *ngIf="!builder.noBuilderPageForUrl"
  (load)="builder.noBuilderPageForUrl = $event ? false : true"
  (error)="builder.noBuilderPageForUrl = true"
><div class="spinner"></div></builder-component>

 <!-- Testimonial Videos -->
 <div class="hidden" id="Darren" (click)="openVideoPlayer('Darren Testimonial', 'https://player.vimeo.com/video/709640626')"></div>
 <div class="hidden" id="Thayne" (click)="openVideoPlayer('Thayne Testimonial', 'https://player.vimeo.com/video/709640671')"></div>
 <div class="hidden" id="Neil" (click)="openVideoPlayer('Neil Roth Testimonial', 'https://player.vimeo.com/video/871900294')"></div>
 <div class="hidden" id="James" (click)="openVideoPlayer('James McGrath Testimonial', 'https://player.vimeo.com/video/810291810')"></div>
 <div class="hidden" id="Hank" (click)="openVideoPlayer('Hank Testimonial', 'https://player.vimeo.com/video/871900340')"></div>
 <div class="hidden" id="DaveJohnson" (click)="openVideoPlayer('Dave Johnson Testimonial', 'https://player.vimeo.com/video/754390507')"></div>