import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-code-entry',
  templateUrl: './referral-code-entry.component.html',
  styleUrls: ['./referral-code-entry.component.scss'],
  
})
export class ReferralCodeEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
