<div class="container">
    <builder-component 
    [data]="builder.data"
    [context]="builder.context"
    model="legal-page-model" [options]="builder.options"
    *ngIf="!builder.noBuilderPageForUrl"
    (load)="builder.noBuilderPageForUrl = $event ? false : true"
    (error)="builder.noBuilderPageForUrl = true"
  ><div class="spinner"></div></builder-component>
</div>

