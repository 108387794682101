import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerComponent } from '../../shared/model/video-player/video-player.component';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  faqData = [
    { title: "Why are the videos and words cut off on my screen?", content: "The app is designed to use the default settings on your phone. If you if you have ZOOM enabled or enlarged text the app may cut off the text, video, or photos. We suggest that you unselect the ZOOM feature while using the app or using a phone with a larger screen can also help." },
    { title: "Should I use groups or tags?", content: "We suggest that you use groups to organize your contacts and keep groups to a minimum. You can use tags for more detailed organization but may complicate the use of the app."},
    { title: "Can I send more than one video or article at a time inside the APP?", content: "Only one (1) source of content/media can be sent for tracking purposes per text or email. This is so that each item you send is uniquely tracked. We recommend sending content in sequence to measure the prospects engagement."},
    { title: "Can I send a text link to multiple prospects at once?", content: "Anti-Spam Laws do not allow you to send to multiple prospects at a time. We also recommend you don't since each link is unique to a single prospect. If you sent to multiple prospects, the link would report back in your app as the same person and not each person you sent it to."},
  ];

  faqExpandedPanels = Array(this.faqData.length).fill(false);

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };
  
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openVideoPlayer(title:string, videoUrl: string) {
    this.dialog.open(VideoPlayerComponent, {
      data: {
        title: title,
        videoUrl: videoUrl,
      },
      panelClass: ["video-player-popup", "w-full", "mx-auto", "sm:max-w-screen-md"]
    });
  }

}
