import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoshipConfigurationService } from '../../services/autoshipConfiguration.service';

@Component({
  selector: 'app-change-autoship-date',
  templateUrl: './change-autoship-date.component.html',
  styleUrls: ['./change-autoship-date.component.sass']
})
export class ChangeAutoshipDateComponent implements OnInit {

  autoshipDate;
  constructor(
    private autoshipConfigurationService: AutoshipConfigurationService,
    public dialogRef: MatDialogRef<ChangeAutoshipDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.autoshipDate = autoshipConfigurationService.autoshipDate;
    console.log(this.autoshipDate);
  }

  ngOnInit(): void {
  }

  dateFilter = (d: Date | null): boolean => {
    const date = (d || new Date()).getDate();
    const currentMonth = (d || new Date()).getMonth();
    const currentYear = (d || new Date()).getFullYear();

    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();
    const todayDate = today.getDate();

    // Allow dates from the 1st to the 11th of future months
    if (currentYear > thisYear) {
        return date >= 1 && date <= 11; // Allow all dates from 1st to 11th in future years
    } else if (currentYear === thisYear) {
        if (currentMonth > thisMonth) {
            return date >= 1 && date <= 11; // Allow all dates from 1st to 11th in future months of the current year
        } else if (currentMonth === thisMonth) {
            // If today's date is greater than 11, disable the current month
            return todayDate > 11 ? false : date >= todayDate && date <= 11;
        }
    }

    return false; // Exclude all previous months and years
  };

  setDate() {
    this.autoshipConfigurationService.autoshipDate = this.autoshipDate;
    this.dialogRef.close();
  }

}
