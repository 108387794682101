import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.sass']
})
export class BioComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {},
    context: {}
  };


  constructor() { }

  ngOnInit(): void {
  }

}
