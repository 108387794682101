import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "../../shared/services/product.service";
import { ConfigService } from "../../shared/services/config.service";
import { DSProduct } from "src/app/modals/dsproduct.modal";
import { UserService } from "../../shared/services/user.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { CompanyService } from "../../shared/services/company.service";
import { ShoppingCartService } from "../../shared/services/shopping-cart.service";
import {
  SwiperConfigInterface,
  SwiperNavigationInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";
import { Router } from "@angular/router";
import { Cart1Service } from "../../shared/services/cart1.service";
import { ItemsListService } from "../../shared/services/itemsList.service";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { RestApiService } from "../../shared/services/restapi.service";
import { MatDialog } from "@angular/material/dialog";
import { LoyaltyProgramComponent } from "../../shared/model/loyalty-program/loyalty-program.component";
import { SideNavBarService } from "../../shared/services/sidenavbar.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {

  builder = {
    noBuilderPageForUrl: false,
    options: {
      cacheSeconds: 0,
      cache: true
    },
    data: {
      categories: this.productService.retailStoreCategories || [],
      webalias: this.user.userServiceModal.WebAlias
    },
    context: {
      // navigate : (category:string)=>{
        // if(category) {
        //   const formattedCategory = category.replace(/\s+/g, '-').toLowerCase();
        //   this.router.navigate([`/category/${formattedCategory}`]);
        // } 
      // }
    }
  };

  public OrderQuantityCount;
  public selectedCategory;
  env;
  products: Array<DSProduct>;
  healthBeautyProduct: Array<DSProduct>;
  shopPacksProduct: Array<DSProduct> = [];
  quarterlyPacksProduct: Array<DSProduct>;
  userService: UserServiceModal;
  itemType = "";
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };
  private navigation: SwiperNavigationInterface = {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  };
  public allItems: { Category; CategoryId }[] = [];
  contentLoaded = false;
  public banners = [];
  public slides = [];
  public BundlesConfig: SwiperConfigInterface = {};

  
  constructor(
    public configService: ConfigService,
    public translate: TranslateService,
    private titleService: Title,
    public productService: ProductService,
    public user: UserService,
    public companyService: CompanyService,
    public shoppingCartService: ShoppingCartService,
    public router: Router,
    public cart1Service: Cart1Service,
    public itemsListService: ItemsListService,
    private apiService: RestApiService,
    private dialog: MatDialog,
    private sidebarNavService: SideNavBarService,
  ) {
    this.userService = user.userServiceModal;
    // this.getAllProductsCategories();

    this.env = environment;
  }
  ngOnInit() {
    this.getAllCategories();
    this.BundlesConfig = {
      observer: true,
      direction: "horizontal",
      slidesPerView: 3,
      spaceBetween: 80,
      keyboard: true,
      navigation: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        740: {
          slidesPerView: 2,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        960: {
          slidesPerView: 3,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
        1280: {
          slidesPerView: 3,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        },
      },
    };

    this.translate.get("global_Company_Title").subscribe((text: string) => {
      this.titleService.setTitle(
        this.translate.instant("pagetitle_home") + " | " + text,
      );
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
    this.slides = this.configService.localSettings.Home.PrimaryBanner;
    this.config = {
      observer: true,
      direction: "horizontal",
      slidesPerView: 1,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    };
  }

  getAllCategories() {
    if (this.productService.retailStoreCategories.length > 0) {
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
      return this.productService.retailStoreCategories;
    }
    
    this.apiService.GetCategories(3).subscribe(() => {
      this.getImageUrlFromDescription(this.productService.retailStoreCategories);     
    });
  }

  getImageUrlFromDescription(categories) {

    // Insert Cover Iamge
    categories.forEach(element => {

      element.providerDescription = "";
      const translationsArray = element.CategoryTranslations || [];
      const englishTranslation = translationsArray.find((t) => t.LanguageCode == "en");
      const { Description } = englishTranslation || {};
      // Description = Description?.split("|") || [];
      element.catImage = `https://avinihealth.corpadmin.directscale.com/CMS/Images/CategoryImages/${element.ID}.jpg` || "https://avinihealth.corpadmin.directscale.com/CMS/Images/Inventory/no_image.jpg";
      element.providerDescription = Description;
    
    });

    this.productService.retailStoreCategories = categories;
    
    // Name Modifications
    categories.forEach((category) => {
      if(category?.Name?.toLowerCase() === "zeolite") {
        category.Name = "Cell Defender";
      }
    });

    // Filter Out these 4 properties
    const hideCategories = {
      11: "Sales Tools & Accessories",
      8: "Swag",
      12: "Travel Packets",
      7: "Prepacks",
      10: "Pets",
    };

    categories = categories.filter((cat) => !Object.prototype.hasOwnProperty.call(hideCategories, cat.ID));

    // Adding 2 New Product links
    categories.push({
      ID: 0,
      productID: 368174,
      Name: "Plus Relief for Pets",
      catImage: "https://avinihealth.corpadmin.directscale.com/CMS/Images/Inventory/AVI-PRODUCT-MOCKUPS-1000X790-h35%20(1).jpg",
      providerDescription: "All Natural Homeopathic,  Works on multiple types of chronic pain and inflammation,  Non-narcotic, non-addictive pain relief."
    });
    
    categories.push({
      ID: 0,
      productID: 368173,
      Name: "Cell Defender for Pets",
      catImage: "https://avinihealth.corpadmin.directscale.com/CMS/Images/Inventory/AVI-PRODUCT-MOCKUPS-1000X790-h34%20(1).jpg",
      providerDescription: "Cell Defender for Pets is the perfect solution for pet owners looking to keep their furry friends healthy and happy. All-natural, non-toxic formula helps remove heavy metals and toxins."
    });

    this.builder.data = this.productService.retailStoreCategories;
    this.builder.data['categories'] = this.productService.retailStoreCategories;
    this.builder.data['webalias'] = this.user.userServiceModal.WebAlias;
  }

  mouseEnter(index) {
    document.getElementById("btn" + index).classList.add("faded-in");
    document.getElementById("btn" + index).classList.remove("faded-out");
    setTimeout(() => {
      document.getElementById("btn" + index).style.opacity = "1";
    }, 590);
  }
  mouseOut(index) {
    document.getElementById("btn" + index).classList.add("faded-out");
    setTimeout(() => {
      document.getElementById("btn" + index).style.opacity = "0";
    }, 590);
    document.getElementById("btn" + index).classList.remove("faded-in");
  }
  getAllProductsCategories() {
    this.selectedCategory = { CategoryId: 0 };
    this.productService
      .getProductByCategory(this.selectedCategory)
      .subscribe((products) => {
        this.productService.orders = products.map((item) => {
          item.Price =
            item.Price ||
            (item.Prices && item.Prices[0] && item.Prices[0].Price);
          return item;
        });

        const uniqueItems = _.uniqBy(products, (x) => x.CategoryId);
        let uniqueRequireProduct = [];
        if (
          this.configService.localSettings.Global.CategoriesToFetch?.length > 0
        ) {
          uniqueItems.filter((x) => {
            if (
              this.configService.localSettings.Global.CategoriesToFetch.indexOf(
                x.Category,
              ) > -1
            ) {
              uniqueRequireProduct.push(x);
            }
          });
        } else {
          uniqueRequireProduct = [...uniqueItems];
        }

        this.itemsListService.selectedCategories = {};
        if (this.selectedCategory.Category == "all") {
          this.itemsListService.selectedCategories["all"] = true;
        }
        this.itemsListService.categoryList = _.map(
          uniqueRequireProduct,
          (object) => {
            return _.pick(object, ["CategoryId", "Category"]);
          },
        );
        this.itemsListService.products = this.productService.orders;
        this.itemsListService.type = "order";
        this.itemsListService.getItemsByCategory(
          this.selectedCategory.Category,
        );
        this.products = this.itemsListService.productList.slice(0.8);
        this.allItems = this.itemsListService.categoryList;
        if (this.allItems) {
          this.healthBeautyProducts();
          this.quarterlyPacksProducts();
          this.shopPacksProducts();
        }
      });
  }

  healthBeautyProducts() {
    this.itemsListService.getItemsByCategory(this.allItems[0]?.Category);
    this.healthBeautyProduct = this.itemsListService.productList.slice(0, 3);
  }
  quarterlyPacksProducts() {
    this.itemsListService.getItemsByCategory(this.allItems[1]?.Category);
    this.quarterlyPacksProduct = this.itemsListService.productList.slice(0, 3);
  }
  shopPacksProducts() {
    this.itemsListService.getItemsByCategory(this.allItems[2]?.Category);
    this.shopPacksProduct = this.itemsListService.productList.slice(0, 3);
  }

  goToShop(id) {
    this.router.navigate(["/product", id]);
  }

  handleProduct(item) {
    if (item.ItemOptions.length > 0) {
      this.router.navigate(["/product", item.ItemID]);
    } else {
      this.OrderQuantityCount = item && item.Quantity ? item.Quantity : 1;
      if (this.cart1Service["orderQuantity"][item.ItemID] >= 1) {
        this.increaseQuantiy("order", item);
      } else {
        this.cart1Service["orderQuantity"][item.ItemID] =
          this.OrderQuantityCount;
        this.cart1Service.addToCart(
          item,
          true,
          item.ItemID,
          false,
          false,
          false,
          true,
        );
      }
    }
  }
  increaseQuantiy(type, item) {
    this.cart1Service.increaseQuantiy(item, type == "autoship", type == "pack");
  }

  goToItem(id, type) {
    this.router.navigate([`/product/${id}`], {
      queryParams: { storeid: 4, itemtype: type },
    });
  }

  openLoyaltyPopup() {
    this.dialog.open(LoyaltyProgramComponent, {
      disableClose: true,
      panelClass: "loyalty-program-dialog",
      autoFocus: false,
    });
  }

  addPackToCart(item) {
    this.addPackFromEnrollStore(item);
    return;

    const packItemCode = item.itemID;
    this.productService.getProductByCategory().subscribe((alltems) => {
      const packItem = alltems.find((item) => item.ItemID == packItemCode);

      if (!packItem) {
        this.addPackFromEnrollStore(item);
        return;
      }

      this.cart1Service.addToCart(
        packItem,
        true,
        packItem.ItemID,
        false,
        false,
        true,
      );
      this.sidebarNavService.showsideBarSummary = true;
    });
  }

  addPackFromEnrollStore(packItem) {
    // Convert to PC for Packs Shopping if he is not Distributor.
    if (this.userService.customerTypeID != 1) {
      this.userService.customerTypeID = 3;
    }

    sessionStorage.removeItem("items");

    const packItemCode = packItem.itemID;
    this.productService
      .getProductByCategory({
        PriceGroup: this.userService.customerTypeID,
        StoreID: 4,
      })
      .subscribe((alltems) => {
        const packItem = alltems.find((item) => item.ItemID == packItemCode);

        if (!packItem) {
          sessionStorage.removeItem("items");
          this.router.navigate([`/product/${packItemCode}`]);
        }

        this.cart1Service.addToCart(
          packItem,
          true,
          packItem.ItemID,
          false,
          false,
          true,
        );
        this.sidebarNavService.showsideBarSummary = true;
      });
  }
  navigate(categoryId){        
    this.router.navigate(["/products", categoryId]);
  }
}
