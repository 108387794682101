<div class="relative">
  <builder-component 
    [data]="builder.data"
    [context]="builder.context"
    model="figma-imports" [options]="builder.options"
    *ngIf="!builder.noBuilderPageForUrl"
    (load)="builder.noBuilderPageForUrl = $event ? false : true"
    (error)="builder.noBuilderPageForUrl = true"
  ><div class="spinner"></div></builder-component>
  <div class="absolute top-0 z-0 sm:block hidden">
    <img src="../../../../assets/svg/left-footer.svg" alt="left">
  </div>
  <div class="absolute sm:top-0 right-0 z-0 top-[50%]">
    <img src="../../../../assets/svg/right-footer.svg" alt="right">
  </div>
</div>
