import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { RestApiService } from '../../shared/services/restapi.service';
import { ApplicantAddress, Enrollment } from 'src/app/modals/enrollment.model';
import { State } from 'src/app/modals/state.modal';
import * as _ from 'lodash';
import { UserService } from '../../shared/services/user.service';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { ConfigService } from '../../shared/services/config.service';
import { UtilityService } from '../../shared/services/utility.service';
import { getSubdomain } from 'tldts';
import { ProductService } from '../../shared/services/product.service';
import { EnrollmentResponse } from 'src/app/modals/enrollmentrespnse.modal';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '../../shared/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { getBaseLocation } from 'src/app/baseUrl';

@Component({
  selector: 'app-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.scss']
})
export class EmailAddressComponent implements OnInit {
  public userInfo: any = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    primaryPhone: "",
    webAlias:"",
    applicantAddress:{
      Street1: "",
      Street2: "",
      Street3: "",
      City: "",
      Region: "",
      PostalCode: "",
      CountryCode: "us"
    }
  };
  userService: UserServiceModal;
  states: Array<State> = [];
  allowedCountries = [];
  country;
  selectedCountry = 'us';
  public EmailCheck: boolean = true;
  public isShowInfo: boolean = false;
  public showEnroller: boolean = false;
  constructor(
    public notificationService: NotificationService,
    public apiService: RestApiService,
    public user: UserService,
    public configService: ConfigService,
    public utilityService: UtilityService,
    public itemsService: ProductService,
    public translate: TranslateService,
    public accountService: AccountService,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.userService = this.user.userServiceModal;
    this.allowedCountries = JSON.parse(
      sessionStorage.getItem("allowedCountries"),
    );
    const selectedCountry =
      sessionStorage.getItem("selectedCountry") === "undefined"
        ? null
        : sessionStorage.getItem("selectedCountry");

    this.selectedCountry = selectedCountry;
    const country = _.find(this.allowedCountries, (item) => {
      return item.CountryCode.toLowerCase() === (selectedCountry || "us");
    });
    this.country = country;
    // this.userInfo.applicantAddress.CountryCode = this.selectedCountry || "us";
    this.getStates();
  }

  ngOnInit(): void {
    this.userInfo.webAlias = this.userService.enrollerInfo.WebAlias;
  }
  
  validEmailData(value) {
    this.userInfo.email = value.replace(/\s/g, "");
    this.verifyUserNameAndEmail();
    this.userInfo.webAlias = this.userService.enrollerInfo.WebAlias;
  }

  verifyUserNameAndEmail() {
    const filter = new RegExp(
      "^([\\w-\\.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([\\w-]+\\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\\]?)$",
    );
    if (this.userInfo.email && filter.test(this.userInfo.email)) {
      this.apiService
        .validateEmailAddress({ EmailAddress: this.userInfo.email })
        .subscribe((result) => {
          if (!result.Data) {
            this.EmailCheck = true;
            this.isShowInfo = true;
            this.notificationService.success("success", "email_available_");
          } else {
            this.notificationService.error("error_", "error_email_exists");
            this.userInfo.email = "";
          }
        });
    }else{
      this.notificationService.error("error_", "error_valid_email");
    }
  }

  getStates(c = "") {
    const country = c || this.country.CountryCode || "US";
    this.apiService
      .getStates(country)
      .subscribe((result) => {
        sessionStorage.setItem(
          JSON.stringify(this.country.CountryCode),
          JSON.stringify(result.Data),
        );
        if (result.Data && result.Data.length > 0) {
          // this.StatesResponse = result.Data;
          this.states = result.Data;

          /**
           * Filter out states for US;
           */
          if (country.toUpperCase() === "US") {
            const excludedStates = ["FM", "AE", "AA", "AP"];
            this.states = this.states.filter(state => excludedStates.indexOf(state.StateCode) == -1);
          }


          this.userInfo.applicantAddress.Region =
            this.states[0]?.StateCode || result.Data[0]?.StateCode;
          // this.userService.mailingAddress.StateName =
          //   this.userService.mailingAddress?.StateName ||
          //   this.states[0]?.StateName ||
          //   result.Data[0]?.StateName ||
          //   "UT";
          // if (!this.StateNameToggel) {
          //   this.getStateName(false);
          //   this.StateNameToggel = true;
          // }
        }
      });
  }
  createAccount(){
    this.configService.commonData.selectedCountry = this.userInfo.CountryCode;
    this.userService.regionMainState = this.userInfo.Region
    this.userService.customerTypeID = 2;
    var request : Enrollment= {
    AssociateID:  0,
      AcceptTerms: true,
      billingAddressSame: false,
      FirstName: this.userInfo.firstName,
      LastName: this.userInfo.lastName,
      RomanizedFirstName: "",
      RomanizedLastName: "",
      LegalFirstName: "",
      LegalLastName: "",
      TaxID: "",
      BirthDate: ((2000) +
        "-" +
        ( 1) +
        "-" +
        (1)
      ).toString(),
      PrimaryPhone: this.userInfo.primaryPhone,
      SecondaryPhone: "",
      TextNumber: "",
      Email: this.userInfo.email,
      Username: this.userInfo.email,
      Password: this.userInfo.password,
      LanguageCode: "en",
      ApplicantAddress: {
        Street1: this.userInfo.applicantAddress.Street1,
        Street2:this.userInfo.applicantAddress.Street2,
        Street3:"",
        PostalCode:this.userInfo.applicantAddress.PostalCode,
        City:this.userInfo.applicantAddress.City,
        Region: this.userService.regionMainState || "UT",
        CountryCode: this.configService.commonData.selectedCountry || "us",
      },
      ShippingAddress: {
        Street1: this.userInfo.applicantAddress.Street1,
        Street2:this.userInfo.applicantAddress.Street2,
        Street3:"",
        PostalCode:this.userInfo.applicantAddress.PostalCode,
        City:this.userInfo.applicantAddress.City,
        Region: this.userService.regionMainState || "UT",
        CountryCode: this.configService.commonData.selectedCountry || "us",
      },
      AssociateTypeID: 2,
      AssociateBaseType: 2,
      SponsorID:  this.userService.enrollerInfo.CustomerId || 2,
      WebPageURL: "",
      WebPageItemID: 0,
      SendEmails: true,
      AssociateCustom: null,
      PlacementOverrides: null,
    }
    this.apiService
    .submitEnrollmentForm(request)
    .subscribe(
      (result: EnrollmentResponse) => {
        if (result.Message === "Success" && result.ErrorDescription == "") {
          this.userService.webOffice.UserName =this.userInfo.email;
          this.userService.webOffice.Password = this.userInfo.password;

          this.loginFunction(
            this.userInfo.email,
            this.userInfo.password
          );
          this.notificationService.success(
            "success",
            this.translate.instant(
              "customer_created_successfully",
            ),
          );
          // sessionStorage.removeItem("FormData");
        } else {
          this.notificationService.error("error_", result.Message);
        }
      },
      (err) => {
        console.error(err);
        this.notificationService.error(
          "error_",
          this.translate.instant(
            "error_occured_try_again",
          ),
        );
      },
    );
  }

  verifyWebAlias(){
    if(this.userInfo.webAlias == this.userService.enrollerInfo.WebAlias || !this.userInfo.webAlias) return;
    this.apiService.getWebsiteInformation_V1(this.userInfo.webAlias).subscribe(res => {
      if (res.Data) {
        this.setSponsor(res.Data);
      }else{
        this.userInfo.webAlias = this.userService.enrollerInfo.WebAlias;
        this.notificationService.error("error_", "webalias_not_exists");
      }
    })
  }

  loginFunction(userName, pass) {
    // this.loadingDetails = true;
    const loginrequest = {
      granttype: "password",
      scope: "office",
      username: userName,
      password: pass,
    };
    this.apiService.Login(loginrequest).subscribe(
      (result) => {
        if (result) {
          const res = result.body;
          try {
            localStorage.setItem("isLoggedIn", "true");
            this.apiService.getCustomer(res.CustomerId).subscribe(
              (data) => {
                // this.loadingDetails = false;
                if (data && data.Data) {
                  this.userService.customerData = data.Data;
                  this.userService.customerTypeID =
                    this.userService.customerData.CustomerType;
                  if (
                    !this.utilityService.isEmptyObject(
                      this.userService.customerData.DefaultShippingAddress,
                    )
                  ) {
                    this.user.setShippingAddress();
                  } else {
                    this.userService.shippingAddress =
                      new UserServiceModal().newshippingAddress;
                  }
                  if (this.userService.customerData.Webalias) {
                    this.apiService
                      .validateWebAlias(this.userService.customerData.Webalias)
                      .subscribe(
                        (res: any) => {
                          if (res.Data) {
                            this.userService.WebAlias =
                              this.userService.customerData.Webalias;
                            this.loginredirect();
                          }
                        },
                        (error) => {
                          if (error.Data && !error.Data.WebAlias) {
                            this.notificationService.error(
                              "error_",
                              "webalias_not_exists",
                            );
                            return false;
                          }
                        },
                        () => {},
                      );
                  } else if (this.userService.customerData.SponsorId) {
                    this.apiService
                      .getSearchCustomerDetail(
                        this.userService.customerData.SponsorId,
                      )
                      .subscribe(
                        (resp: any) => {
                          if (resp.Data) {
                            this.apiService
                              .validateWebAlias(resp.Data.WebAlias)
                              .subscribe(
                                (res: any) => {
                                  if (res.Data) {
                                    this.userService.WebAlias =
                                      resp.Data.WebAlias;
                                    this.loginredirect();
                                  }
                                },
                                (error) => {
                                  if (error.Data && !error.Data) {
                                    this.notificationService.error(
                                      "error_",
                                      "webalias_not_exists",
                                    );
                                    return false;
                                  }
                                },
                                () => {},
                              );
                          }
                        },
                        (error) => {
                          if (error.Data && !error.Data.WebAlias) {
                            this.notificationService.error(
                              "error_",
                              "webalias_not_exists",
                            );
                            return false;
                          }
                        },
                        () => {},
                      );
                  }
                } else {
                  this.notificationService.error(
                    "error_",
                    "error_occured_try_again",
                  );
                }
              },
              () => {
                this.notificationService.error(
                  "error_",
                  "error_occured_try_again",
                );
                // this.loadingDetails = false;
              },
              () => {},
            );
          } catch (successEx) {
            this.notificationService.error("error_", "error_occured_try_again");
            // this.loadingDetails = false;
            console.error("ex", successEx);
          }
        } else {
          // this.loadingDetails = false;
          this.notificationService.error("error_", "error_occured_try_again");
        }
      },
      () => {
        // this.loadingDetails = false;
        this.notificationService.error("error_", "error_occured_try_again");
      },
      () => {},
    );
  }

  loginredirect() {
    // let newUrl: string;
    // const subdomain = window.location.host;//getSubdomain(window.location.host);
    // if (subdomain) {
    //   newUrl = window.location.href.replace(
    //     subdomain,
    //     this.userService.WebAlias,
    //   );
    // }

    if (
      this.itemsService.selectedPacks.length ||
      this.itemsService.selectedOrderItems.length
    ) {
      this.userService.checkItems = true;

      // window.location.assign(newUrl.replace("signup", "checkout"));
      this.router.navigate(["/checkout"]);
    } else if (this.itemsService.selectedAutoOrderItems.length) {
      this.userService.checkItems = true;
      this.router.navigate(["/home"]);
      // window.location.assign(newUrl.replace("signup", "home"));
    } else {
      this.router.navigate(["/home"]);
      // window.location.assign(newUrl.replace("signup", "home"));
    }
  }

  setSponsor(SelectedSponsor) {
    if (SelectedSponsor && SelectedSponsor.CustomerId && SelectedSponsor.WebAlias
    ) {
      const baseurl = getBaseLocation();
      sessionStorage.setItem("enrollerSet", "true");
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length === 0) {
          location.href = location.pathname.replace(
            baseurl,
            baseurl === "/"
              ? "/" + SelectedSponsor.WebAlias + "/"
              : `/${SelectedSponsor.WebAlias}`,
          );
        } else {
          this.userService.WebAlias = SelectedSponsor.WebAlias;
          localStorage.setItem("userService", JSON.stringify(this.userService));
          location.href = location.pathname.replace(
            baseurl,
            baseurl === "/"
              ? "/" + SelectedSponsor.WebAlias + "/"
              : `/${SelectedSponsor.WebAlias}`,
          );
        }
      });
    } else {
      this.notificationService.error("error_", "webalias_not_exists");
      return false;
    }
  }
}
